.c-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include mq(lg) {
        display: flex;
    }
    &__item {
        
        @include mq(lg) {
            margin-left: 2.5rem;
        }
    }
    &__link {
        display: block;
        padding: 12px;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;

        @include mq(lg) {
            padding: 0;
        }
        &:hover {
            background-color: $color-black;
            color: $color-white;

            @include mq(lg) {
                background-color: transparent;
                transform: translateY(-2px);
            }
        }
    }
}