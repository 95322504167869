.js-zoom {
    position: relative;
    overflow: hidden;
    z-index: 1;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: #fafafa;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
    }
    &.is-active {

        & img {
            position: relative;
            z-index: 2;

            @include animation(
                $name: kf-zoom,
                $timing-function: ease,
                $duration: 2s,
            );
        }
    }
}

@keyframes kf-zoom {
    0% {
        transform: scale(1.2);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}