.p-loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: $color-main;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5vw;
    padding-right: 5vw;
    transition: opacity 1s, visibility 1s;

    &.is-active {
        opacity: 0;
        visibility: hidden;
    }
    &__icon {
        position: relative;
        
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: $color-main;
            position: absolute;
            left: 0;
            top: 0;
            transform-origin: right;
        }
        &.is-active {
            &::before {
                @include animation(
                    $name: kf-loading,
                    $timing-function: ease,
                    $duration: 1.8s,
                    $delay: 0.75s
                );
            }
        }
    }
}

@keyframes kf-loading {
    0% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(0);
    }
}