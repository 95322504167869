.p-about {

    @include mq(lg) {
        margin-bottom: 6em;
        position: relative;

        &::before {
            content: '';
            display: block;
            width: 520px;
            height: 520px;
            background-image: url(../svg/logo--white.svg);
            background-size: contain;
            position: absolute;
            right: -9rem;
            top: -18vh;
            z-index: 5;
        }
    }

    &__heading, &__title {
        margin-bottom: 1rem;
    }
    &__header {
        padding-top: 10em;
        padding-bottom: 3em;
        background-color: $color-main;
        color: $color-white;
        position: relative;
    }
    &__heading {

        @include mq(lg) {
            max-width: 70%;
            margin-bottom: 3rem;
        }
    }
    &__title {
        font-size: 1.5rem;

        @include mq(lg) {
            font-size: 4rem;
            line-height: 1.5;
            margin-bottom: 2rem;
        }
    }
    &__caption {
        margin-bottom: 3rem;

        @include mq(lg) {
            font-size: 1.25rem;
            margin-bottom: 6em;
        }
    }
    &__logo {
        max-width: 200px;

        @include mq(lg) {
            max-width: 350px;
            margin-left: calc(50% + 5vw);
        }
    }
    &__footer {
        @include mq(lg) {
            display: flex;
            align-items: flex-end;
            margin-top: -9em;
            position: relative;
            z-index: 1;
        }
    }
    &__media {
        @include mq(lg) {
            width: 50%;
        }
    }
    &__profile {
        padding: 2em 5vw 3em;
        position: relative;

        @include mq(lg) {
            width: 50%;
            padding: 4rem 5vw;
            height: fit-content;

            &::after {
                content: '';
                width: 45vw;
                height: 1px;
                background-color: $color-black;
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 1;
            }
        }
        &-heading, &-title {
            margin-bottom: 1rem;

            @include mq(lg) {
                margin-bottom: 2.5rem;
            }
        }
        &-heading {
            font-family: $font-en;
            font-weight: black;
            color: $color-main;

            @include mq(lg) {
                font-size: 1.25rem;
            }
            & .t {
                font-size: 2rem;
                line-height: 1.5;

                @include mq(lg) {
                    font-size: 3.5rem;
                    line-height: 1;
                }
            }
        }
        &-caption {
            font-size: 12px;
            line-height: 2;

            @include mq(lg) {
                font-size: 1rem;
                line-height: 2.4;
            }
        }
    }
}