.p-header {
    height: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 8vh;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq(lg) {
        padding-top: 50px;
    }
    &__logo {
        &-link {
            transition: color 0.3s ease;
            
            &:hover {
                color: $color-main;
            }
        }
    }
    &__hamburger {
        
        @include mq(lg) {
            display: none;
        }
    }
    &__nav {
        display: none;

        @include mq(lg) {
            display: block;
        }
    }
    &__menu {
        display: flex;
        align-items: center;
    }
    &__item {
        display: block;
        margin-left: 2rem;
    }
    &__link {
        padding-bottom: 14px;
        position: relative;

        &::after {
            content: '';
            width: 100%;
            height: 2px;
            background-color: $color-main;
            position: absolute;
            left: 0;
            bottom: 0;
            transform: scaleX(0);
            transform-origin: center;
            transition: transform 0.3s ease;
        }
        &:hover {
            &::after {
                transform: scaleX(1);
            }
        }
    }
}