.p-footer {

    &__inner {
        
        @include mq(lg) {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4rem;
        }
    }
    &__info {
        @include mq(lg) {
            width: 65%;
            display: flex;
            align-items: center;
        }
    }
    &__logo {
        max-width: 100px;
        margin-bottom: 2rem;

        @include mq(lg) {
            max-width: 140px;
            padding-right: 2rem;
        }
    }
    &__address {
        margin-bottom: 1.5rem;
    }
    &__contact {
        @include mq(lg) {
            width: 35%;
            text-align: right;
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-left: 1px solid $color-white;
        }
    }
    &__nav {
        margin-bottom: 2rem;

        @include mq(lg) {
            margin-bottom: 0;
        }
    }

    &__bottom {
        padding: 1rem;
        text-align: center;
        display: flex;
        flex-direction: column-reverse;

        @include mq(lg) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 2rem;
        }
    }
}