.p-book {
    padding-top: 10em;
    padding-bottom: 10em;
    background-color: $color-main;
    color: $color-white;

    &__header {
        margin-bottom: 4rem;
    }
    &__logo, &__media, &__brand {
        margin-left: auto;
        margin-right: auto;
    }
    &__logo {
        max-width: 240px;
        margin-bottom: 3rem;
    }
    &__media {
        max-width: 350px;
        margin-bottom: 3rem;
    }
    &__brand {
        max-width: 420px;
    }
    &__body {
        text-align: center;
        margin-bottom: 5em;
    }
    &__buy {
        font-size: 2.4rem;
        margin-bottom: 1rem;

        @include mq(lg) {
            font-size: 4rem;
        }
    }
    &__site {
        padding-top: 3rem;

        @include mq(lg) {
            display: flex;
            padding-top: 5rem;
        }
        &-item {
            &:nth-child(1) {
                margin-bottom: 3rem;

                @include mq(lg) {
                    margin-bottom: 0;
                    
                    & .p-book__site-header {
                        padding-bottom: calc(1rem + 27.84px);
                    }
                }
            }

            @include mq(lg) {
                width: 50%;

            }
        }
        &-header, &-media {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1.5rem;
        }
        &-header {
            max-width: 180px;
        }
        &-media {
            max-width: 220px;
        }
        &-body {
            text-align: center;
        }
        &-link {
            font-size: 12px;
        }
    }
    &__caption {
        line-height: 2;
    }
}