.c-hamburger {
    width: 28px;
    height: 20px;
    position: relative;
    z-index: 999;
    cursor: pointer;

    &__line {
        display: block;
        width: 28px;
        height: 2px;
        background-color: $color-white;
        position: absolute;
        left: 0;
        transition: opacity 0.1s ease, transform 0.3s ease, top 0.3s ease;

        &1 {
            top: 0;
        }
        &3 {
            top: 12px;
        }
    }
    &__check {
        display: none;

        &:checked {
            & ~ .c-hamburger__button {
                position: fixed;
                
                & .c-hamburger__line1 {
                    transform: rotate(45deg);
                    top: 8px;
                }
                & .c-hamburger__line3 {
                    transform: rotate(135deg);
                    top: 8px;
                }
            }
            & ~ .c-hamburger__nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &__button {
        position: relative;
        z-index: 100;
    }
    &__nav {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 99;
        background-color: rgba($color-black, 0.8);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;

        padding-top: 60px;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    &__item {
        border-bottom: 1px dashed $color-white;
    }
    &__social {
        display: inline-block;
        width: 50px;
        margin-top: 1rem;
    }
    &__link {
        display: block;
        padding: 12px;
        color: $color-white;

        &:hover {
            opacity: 0.8;
        }
    }
}