.c-container {
    max-width: calc(1200px + 10vw);
    margin-left: auto;
    margin-right: auto;
    padding-left: 5vw;
    padding-right: 5vw;
    
    &--s {
        max-width: calc(800px + 10vw);
        margin-left: auto;
        margin-right: auto;
        padding-left: 5vw;
        padding-right: 5vw;
    }
}