body {
    font-family: "source-han-sans-japanese", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 1.75;

    @include mq(lg) {
        font-size: 16px;
    }
}

ul, ol {
    padding-left: 0;
}

li {
    list-style: none;
}

img, svg {
    max-width: 100%;
    width: 100%;
    height: auto;
    vertical-align: middle;
}

a {
    color: inherit;
    text-decoration: none;
    transition: opacity 0.3s;

    &:hover {
        opacity: 0.7;
    }
}