.p-gallary {
    position: relative;
    overflow: hidden;
    
    &__inner {
        display: flex;
        animation: 20s linear infinite xScroll;
    }
    &__image {
        display: block;
        margin-right: 2px;

        @include mq(lg) {
            margin-right: 10px;
        }
        @include mq(xxl) {
            margin-right: 20px;
        }
    }
}

@keyframes xScroll {
    0%     { transform: translateX(0);}
    100% { transform: translateX(-100%);} 
}