/*=====
Mixin
=====*/

 $breakpoints: (
  'sm': '(min-width: 520px)',
  'md': '(min-width: 7680px)',
  'lg': '(min-width: 960px)',
  'xl': '(min-width: 1280px)',
  'xxl': '(min-width: 1920px)',
 );

 @mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
 }

 @mixin animation($name, $duration: 1s, $timing-function: ease, $delay: 0s, $iteration-count: 1, $direction: normal, $fill-mode: forwards) {
  animation: {
      name: $name;
      duration: $duration;
      timing-function: $timing-function;
      delay: $delay;
      iteration-count: $iteration-count;
      direction: $direction;
      fill-mode: $fill-mode;
  }
}