.js-fadein {
    opacity: 0;
    &.is-active {
        @include animation(
            $name: kf-fadeIn,
            $timing-function: ease,
            $duration: 1.2s
        );
    }
    // アニメーションの開始遅延
    @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
            animation-delay: 0.02s * $i;
        }
    }
}

@keyframes kf-fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}