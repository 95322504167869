.js-left {
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: $color-white;
        position: absolute;
        left: 0;
        top: 0;
        transform-origin: right;
    }
    &--main {
        &::before {
            background-color: $color-main;
        }
    }
    &.is-active {
        &::before {
            @include animation(
                $name: kf-left,
                $timing-function: ease,
                $duration: 1.8s
            );
        }
    }
    // アニメーションの開始遅延
    @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
            animation-delay: 0.02s * $i;
        }
    }
}

@keyframes kf-left {
    0% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(0);
    }
}