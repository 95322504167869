.p-mainvisual {
    position: relative;
    color: $color-white;

    &::before {
        content: '';
        display: block;
        padding-top: 100vh;
    }
    &__inner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        z-index: 2;

        @include mq(xxl) {
            max-width: 100%;
            margin: 0 calc(50% - 50vw);
        }
    }
    &__logo {
        max-width: 100px;
        margin-top: 5vh;
        margin-bottom: 4em;

        @include mq(lg) {
            max-width: 120px;
            margin-bottom: 3em;
        }
        @include mq(xxl) {
            max-width: 200px;
            margin-bottom: 9em;
        }
    }
    &__title {

        & .subtitle {
            font-size: 1.4rem;

            @include mq(lg) {
                font-size: 2.4rem;
            }
            @include mq(xxl) {
                font-size: 3rem;
            }
        }
        & .title {
            font-size: 2.4rem;
            line-height: 1.25;

            @include mq(lg) {
                font-size: 6rem;
                line-height: 0.8;
                margin-bottom: 1.5rem;
            }
            @include mq(xxl) {
                font-size: 9rem;
            }
        }
        & .time {
            font-size: 1.1rem;

            @include mq(lg) {
                font-size: 1.8rem;
            }
        }
    }
    &__sale {
        padding-top: 1.5rem;

        @include mq(lg) {
            font-size: 1.25rem;
        }
    }
    &__swiper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;

        &-control {
            display: none;

            @include mq(lg) {
                position: absolute;
                right: 5vw;
                top: 25vh;
                z-index: 10;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
        &-next, &-prev, &-pagination {
            position: relative;
        }
        &-next, &-prev {
            left: 0;
            right: 0;
            width: 32px;
            height: 32px;

            @include mq(xxl) {
                width: 48px;
                height: 48px;
            }
            &::after {
                content: '';
                display: none;
            }
            & img {
                width: 60%;
                height: 60%;
            }
        }
        &-pagination {
            margin-bottom: 24px;

            & .swiper-pagination-bullet {
                display: block;
                width: 24px;
                height: 48px;
                background-color: transparent;
                font-family: $font-en;
                font-size: 24px;
                font-weight: 700;
                opacity: 1;

                @include mq(xxl) {
                    font-size: 36px;
                    width: 48px;
                    height: 72px;
                }
            }
            & .swiper-pagination-bullet-active {
                color: $color-mail;
            }
        }
        &-next {
            margin-bottom: 32px;
        }
    }
    &__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.swiper-slide-active img,
.swiper-slide-duplicate-active img,
.swiper-slide-prev img{
  animation: zoomUp 10s linear 0s 1 normal both;  
}

@keyframes zoomUp {
    0% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
}