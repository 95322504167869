/*=====
変数
=====*/

// 色
$color-black: #000000;
$color-white: #ffffff;
$color-main: #072550;
$color-footer: #0F3655;
$color-mail: #F39800;

// フォント
$font-en: "sofia-pro-condensed", sans-serif;