.l-header {
    background-color: transparent;
    color: $color-white;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;

    @include mq(lg) {
        height: 80px;
    }
}