.p-profile {
    padding-bottom: 10em;

    @include mq(lg) {
        padding-bottom: 0;
        position: relative;

        &::before {
            content: '';
            width: 360px;
            height: 360px;
            background-image: url(../svg/logo--blue.svg);
            background-size: contain;
            position: absolute;
            left: 5vw;
            top: -9em;
            z-index: -1;
        }
    }
    &__inner {
        display: flex;
        flex-direction: column-reverse;

        @include mq(lg) {
            flex-direction: row;
            align-items: flex-end;
        }
    }
    &__info {
        position: relative;

        @include mq(lg) {
            width: 50%;
            padding-bottom: 6em;

            // &::after {
            //     content: '';
            //     width: 65vw;
            //     height: 1px;
            //     background-color: $color-black;
            //     position: absolute;
            //     left: calc(50% - 30vw);
            //     top: -6em;
            //     z-index: 1;
            // }
        }
        @include mq(xxl) {
            &::after {
                display: none;
            }
        }
    }
    &__title {
        font-size: 1.15rem;
        margin-bottom: 1.5rem;

        @include mq(lg) {
            font-size: 1.5rem;
        }
    }
    &__caption {
        margin-bottom: 3rem;

        @include mq(lg) {
            margin-bottom: 6em;
        }
    }
    &__supervisor {
        font-weight: 600;
        
        @include mq(lg) {
            font-size: 1.15rem;
        }
        &-item {
            margin-bottom: 1rem;

            @include mq(lg) {
                margin-bottom: 1.5rem;
            }
            & .en {
                font-family: $font-en;
                font-weight: 700;
            }
        }
        &-company {
            font-size: 0.8rem;
        }
    }
    &__media {
        position: relative;
        width: 100vw;
        margin: 0 calc(50% - 50vw) 3rem;

        @include mq(lg) {
            width: 50vw;
            margin-left: auto;
            margin-bottom: 0;
        }
    }
    &__detail {
        position: absolute;
        left: 5vw;
        top: 15%;
        z-index: 1;
        text-align: right;

        @include mq(lg) {
            left: 0;
            top: 18%;
        }
    }
    &__logo {
        max-width: 80px;
        margin-left: auto;

        @include mq(lg) {
            min-width: 200px;
        }
    }
    &__name {
        font-weight: 700;

        & .s {
            font-size: 10px;
            padding-right: 0.5rem;

            @include mq(lg) {
                font-size: 1rem;
            }
        }
        & .name {
            font-size: 12px;

            @include mq(lg) {
                font-size: 1.5rem;
            }
        }
    }
}